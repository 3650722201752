import React from "react"
import Layout from "../components/layouts/Layout/layout"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import TitreBlock from "../components/blocks/TitreBlock/TitreBlock"
import componentFactory from "../utils/componentFactory"
import "./faq-template.scss"
import Seo from "../components/seo"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"

const FaqTemplate = ({ pageContext }) => {
  const item = pageContext.item.attributes
  const page = pageContext.page.attributes

  const components = page.contenu.map(i => componentFactory(i, pageContext))

  return (
    <>
      <Seo
        description={page.metaDescription}
        title={item.titre}
        lang="fr"
      ></Seo>
      <Layout title={item.titre}>
        <article className={(item.categorie.data.attributes.slug) + " faq_template"}>
          <div 
            className={(item.categorie.data.attributes.slug) + "-background titre-background"}
            >
              <TitreBlock
              title={item.titre}
              titleColor={item.couleurTitre}
              baseline={item.resume}
              alignment={"center"}
              level="h1"
            />
          </div>

          <ReactMarkdown 
          className="Article-markdown"
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          >
            {item.contenu}
          </ReactMarkdown>
        </article>

        {components}
      </Layout>
    </>
  )
}

export default FaqTemplate
